<template>
  <card>
    <div class="profile-settings__card">
      <div class="profile-settings__title-wrapper">
        <h4 class="profile-settings__title">Session</h4>
        <div class="profile-settings__sub-title" v-if="email">{{ email }}</div>
      </div>
      <button
        class="button button_danger profile-settings__button btn-logout"
        @click="logOutHandler"
      >
        Log out
      </button>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { ROUTE_LOGOUT } from "@/constants";
import { mapState } from "vuex";
import { saveLog } from "@/modules/logger";

export default {
  components: { Card },

  computed: {
    ...mapState({
      email: ({ user }) => user.email,
    }),
  },

  methods: {
    logOutHandler() {
      saveLog("log-out");
      this.$router.push({ name: ROUTE_LOGOUT }, null, () => {});
    },
  },
};
</script>
