<template>
  <profile-layout class="profile-settings">
    <change-password />
    <change-email />
    <logout />
    <super-user v-if="isSuperUser" />
  </profile-layout>
</template>

<script>
import ProfileLayout from "@/components/profile/profileLayout";
import ChangePassword from "./components/changePassword";
import ChangeEmail from "./components/changeEmail";
import SuperUser from "./components/superUser";
import Logout from "./components/logout";
import { mapState } from "vuex";
import logVisiting from "@/mixins/logVisiting";

export default {
  name: "settingsPage",

  components: {
    ProfileLayout,
    ChangePassword,
    ChangeEmail,
    Logout,
    SuperUser,
  },

  mixins: [logVisiting],

  computed: {
    ...mapState({
      isSuperUser: ({ user }) => user.is_superuser,
    }),
  },
};
</script>
