<template>
  <card>
    <div class="profile-settings__card">
      <h4 class="profile-settings__title">Admin</h4>
      <button
        class="button button_danger profile-settings__button btn-super-user"
        @click="showSuperUserPopup"
      >
        Super User
      </button>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { POPUP_SUPER_USER } from "@/constants";

export default {
  components: { Card },

  inject: {
    showModal: { default: () => {} },
  },

  methods: {
    showSuperUserPopup() {
      this.showModal(POPUP_SUPER_USER);
    },
  },
};
</script>
