<template>
  <card>
    <div class="profile-settings__card">
      <h4 class="profile-settings__title">Email</h4>
      <button
        class="button button_blue profile-settings__button btn-change-email"
        @click="showChangeEmail"
      >
        Change email address
      </button>
    </div>
  </card>
</template>

<script>
import Card from "@/components/card";
import { POPUP_CHANGE_EMAIL } from "@/constants";
import { saveLog } from '@/modules/logger';

export default {
  components: { Card },

  inject: {
    showModal: { default: () => {} },
  },

  methods: {
    showChangeEmail() {
      saveLog("change-email-selected");
      this.showModal(POPUP_CHANGE_EMAIL);
    },
  },
};
</script>
